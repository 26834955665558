import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { currentSectionIndex } from "../../redux/actions/fullpage";

import { setVhUnit, changeOverlayColor, handleKeyNav } from "../../helpers";

import "../../styles/pages/traderadius/index.scss";

import StartLayout from "../../components/pages/portfolio/StartLayout";
import GoalLayout from "../../components/pages/portfolio/GoalLayout";
import CoreFeaturesLayout from "../../components/pages/portfolio/CoreFeaturesLayout";
import WorkLayout from "../../components/pages/portfolio/WorkLayout";
import MobileUiLayout from '../../components/pages/portfolio/MobileUiLayout'
import FeedbackLayout from '../../components/pages/portfolio/FeedbackLayout'
import Overlay from "../../components/overlay/Overlay";
import ContactUs from "../../components/contact-us";
import Layout from "../../components/Layout";
import Menu from "../../components/menu";

// goal image

import displaySmPng from '../../../static/images/portfolio/monitor-sm.png'
import displaySmWebp from '../../../static/images/portfolio/monitor-sm.webp'
import displayLgPng from '../../../static/images/portfolio/monitor-lg.png'
import displayLgWebp from '../../../static/images/portfolio/monitor-lg.webp'

// goal video

import videoMp4 from '../../../static/videos/traderadius/video.mp4'

// view images

import Site1SmJpg from "../../../static/images/portfolio/traderadius/view/view-first-small.jpg";
import Site1LgJpg from "../../../static/images/portfolio/traderadius/view/view-first-large.jpg";
import Site1SmWebp from "../../../static/images/portfolio/traderadius/view/view-first-small.webp";
import Site1LgWebp from "../../../static/images/portfolio/traderadius/view/view-first-large.webp";

import Site2SmJpg from "../../../static/images/portfolio/traderadius/view/view-second-small.jpg";
import Site2LgJpg from "../../../static/images/portfolio/traderadius/view/view-second-large.jpg";
import Site2SmWebp from "../../../static/images/portfolio/traderadius/view/view-second-small.webp";
import Site2LgWebp from "../../../static/images/portfolio/traderadius/view/view-second-large.webp";

// work images

import work1LgJpg from "../../../static/images/portfolio/traderadius/work/work-1-lg.jpg"
import work1SmJpg from "../../../static/images/portfolio/traderadius/work/work-1-sm.jpg"
import work1LgWebp from "../../../static/images/portfolio/traderadius/work/work-1-lg.webp"
import work1SmWebp from "../../../static/images/portfolio/traderadius/work/work-1-sm.webp"

import work2LgJpg from "../../../static/images/portfolio/traderadius/work/work-2-lg.jpg"
import work2SmJpg from "../../../static/images/portfolio/traderadius/work/work-2-sm.jpg"
import work2LgWebp from "../../../static/images/portfolio/traderadius/work/work-2-lg.webp"
import work2SmWebp from "../../../static/images/portfolio/traderadius/work/work-2-sm.webp"

import work3LgJpg from "../../../static/images/portfolio/traderadius/work/work-3-lg.jpg"
import work3SmJpg from "../../../static/images/portfolio/traderadius/work/work-3-sm.jpg"
import work3LgWebp from "../../../static/images/portfolio/traderadius/work/work-3-lg.webp"
import work3SmWebp from "../../../static/images/portfolio/traderadius/work/work-3-sm.webp"

// mobile ui images

import Ui1LgPng from '../../../static/images/portfolio/traderadius/mobile-ui/ui-1-lg.png'
import Ui1LgWebp from '../../../static/images/portfolio/traderadius/mobile-ui/ui-1-lg.webp'

import Ui2LgPng from '../../../static/images/portfolio/traderadius/mobile-ui/ui-2-lg.png'
import Ui2LgWebp from '../../../static/images/portfolio/traderadius/mobile-ui/ui-2-lg.webp'

import Ui3LgPng from '../../../static/images/portfolio/traderadius/mobile-ui/ui-3-lg.png'
import Ui3LgWebp from '../../../static/images/portfolio/traderadius/mobile-ui/ui-3-lg.webp'

import Ui4LgPng from '../../../static/images/portfolio/traderadius/mobile-ui/ui-4-lg.png'
import Ui4LgWebp from '../../../static/images/portfolio/traderadius/mobile-ui/ui-4-lg.webp'

import Ui5LgPng from '../../../static/images/portfolio/traderadius/mobile-ui/ui-5-lg.png'
import Ui5LgWebp from '../../../static/images/portfolio/traderadius/mobile-ui/ui-5-lg.webp'

// feedback images

import fbJpg from '../../../static/images/portfolio/traderadius/feedback/feedback.jpg'
import fbWebp from '../../../static/images/portfolio/traderadius/feedback/feedback.webp'
import fb2Jpg from '../../../static/images/portfolio/traderadius/feedback/feedback-2.jpg'
import fb2Webp from '../../../static/images/portfolio/traderadius/feedback/feedback-2.webp'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(currentSectionIndex(0));
  }, []);

  const onOrientationChange = () => {
    setTimeout(() => setVhUnit(), 500);
  };

  useEffect(() => {
    setVhUnit();

    window.addEventListener("orientationchange", onOrientationChange);

    return () =>
      window.removeEventListener("orientationchange", onOrientationChange);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const isMobile = window.innerWidth < 992;

    const mobileClass = [
      'is-white-image-one',
      'features-gallery',
      'mobile-ui-carousel'
    ];

    const desktopClass = [
      'features-gallery',
      'mobile-ui-carousel'
    ];

    changeOverlayColor(isMobile ? mobileClass : desktopClass);
  };

  /**
   * this function for navigation keyboard
   */
  const handleKeyUp = React.useCallback((event) => {
    const navigationLink = {
      prevSite: "/portfolio/spp/",
      nextSite: "/portfolio/bodyshops/",
    };

    handleKeyNav(event.key, navigationLink);
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);

    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [handleKeyUp]);

  return (
    <Layout>
      <Helmet>
        <title>Zimalab Portfolio | TradeRadius</title>
        <meta name="description" content="Zimalab developed a real-time dashboard with personalized signals for currency trading and subscription-based access"/>
        <meta name="Keywords" content="saas, dashboard, vue.js developer, php developer, symfony developer, vue.js software, symfony software, fintech software, tradingview api, sms notifications software, trading software, saas software, "/>
      </Helmet>

      <main className="traderadius-page">
        <StartLayout
          title="Trade"
          titleTwoPart="Radius"
          subTitle="Trading dashboard"
          subTitleLast='FINANCE'
        />

        <GoalLayout
          video={ videoMp4 }
          goalSmJpg={ displaySmPng }
          goalLgJpg={ displayLgPng }
          goalSmWebp={ displaySmWebp }
          goalLgWebp={ displayLgWebp }
          typeImage="png"
        >
          Build a real-time dashboard with personalized
          <div className="goal__description-last--line">
            signals for currency trading
          </div>
        </GoalLayout>

        <CoreFeaturesLayout
          items={[
            "Real-time dashboard with personalized trading signals",
            "Customizable dashboards with financial data widgets and charts by TradingView",
            "News portal with comments and discussions",
            "Group chats for communicating with mentors and other traders",
            "Bonus program for active community members",
            "Multi-tiered subscription plans",
            "SMS and email notifications system"
          ]}
          timeline='Timeline:'
          timelineData='2021 - now'
          link='https://traderadius.com/'
          const coreFeaturesGallery = {{
            Site1SmJpg,
            Site1LgJpg,
            Site1SmWebp,
            Site1LgWebp,

            Site2SmJpg,
            Site2LgJpg,
            Site2SmWebp,
            Site2LgWebp,
          }}
          typeImage='jpg'
        />

        <WorkLayout
          title="Signals"
          subTitle="Real-time signals"
          listItems={[
            "User receives signals in real-time - speed is crucial in trading",
            "User preferences in trading strategies are determined using a questionnaire",
            "Signal-generating model is customized for each user"
          ]}
          imageLgSecondary={ work1LgJpg }
          imageSmSecondary={ work1SmJpg }
          imageLgPrimary={ work1LgWebp }
          imageSmPrimary={ work1SmWebp }
          alt="image"
        />

        <WorkLayout
          isReversed={ true }
          title="Dashboards"
          subTitle="Custom dashboards"
          listItems={[
            "Data on widgets and charts updates in real-time",
            "Add any of the 10 financial data widgets and charts, move and resize them",
            "Financial data for widgets and charts is provided by TradingView"
          ]}
          imageLgSecondary={ work2LgJpg }
          imageSmSecondary={ work2SmJpg }
          imageLgPrimary={ work2LgWebp }
          imageSmPrimary={ work2SmWebp }
          alt="image"
        />

        <WorkLayout
          title="Plans"
          subTitle="Subscription plans"
          listItems={[
            "Free, Silver, Gold and PRO plans to choose from",
            "Upgrade plans in the middle of billing cycle - cost is calculated pro rata",
            "Buy extra features like configuration backtesting as Add-ons"
          ]}
          imageLgSecondary={ work3LgJpg }
          imageSmSecondary={ work3SmJpg }
          imageLgPrimary={ work3LgWebp }
          imageSmPrimary={ work3SmWebp }
          isWhiteImageWorkOne={ true }
          alt="image"
        />

        <MobileUiLayout
          items={[
            {
              imageLgWebp: Ui1LgWebp,
              imageLgPng: Ui1LgPng
            },
            {
              imageLgWebp: Ui2LgWebp,
              imageLgPng: Ui2LgPng
            },
            {
              imageLgWebp: Ui3LgWebp,
              imageLgPng: Ui3LgPng
            },
            {
              imageLgWebp: Ui4LgWebp,
              imageLgPng: Ui4LgPng
            },
            {
              imageLgWebp: Ui5LgWebp,
              imageLgPng: Ui5LgPng
            }
          ]}
        />

        <FeedbackLayout
          reviewImagesLg={[
            {
              imageWebp: fbWebp,
              imageJpg: fbJpg
            },
            {
              imageWebp: fb2Webp,
              imageJpg: fb2Jpg
            }
          ]}
        />

        <ContactUs
          hasNavigation
          prevProject={"/portfolio/spp/"}
          nextProject={"/portfolio/bodyshops/"}
        />

        <Overlay />
        <Menu />
      </main>
    </Layout>
  );
};
